import React from 'react'
import CreateCart from './createCart'
import CreateCartV1 from './cart/v1/createCart'

const getIDFromPathname = pathname =>
  pathname
    .split('/')
    .pop()

function CartOfferItem(props) {
  const id = getIDFromPathname(props.location.pathname)
  return (
    props.location.state?.rule?.cartType === 'page'
      ? <CreateCart {...props} id={id} />
      : <CreateCartV1 {...props} id={id} />
  )
}

export default CartOfferItem;