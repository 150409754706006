import React, {
    Fragment,
    useState,
  } from 'react'
  import update from 'immutability-helper'
  import equal from 'deep-equal'
  import { 
    Badge,
    Button,
    ButtonGroup,
    Form,
    Layout,
    Page,
  } from "@shopify/polaris"
  import { withFirebase } from '../../../providers/firebase'
  import { doc } from 'firebase/firestore';
  import { useDocumentData } from 'react-firebase-hooks/firestore';
  import { navigate } from "gatsby"
  import CartPreview from './cartPreview'
  import './create.css'
  import Constants from '../../../helpers/constants'
  import createRule from '../../../helpers/createRule'
  import { updateRule, deleteRule } from '../../../helpers/updateRule'
  import CartOfferType from './cart/cartOfferType'
  import getSymbolFromCurrency from 'currency-symbol-map';
  import removeCartScript from '../../../helpers/removeCartScript';
  import CreateOfferSteps from './createOfferSteps';
  import OfferStatusButton from './common/offerStatusButton/offerStatusButton';
  import formatLastSavedDate from '../../../helpers/formatLastSavedDate'
  import './common.css';
  // Source: https://stackoverflow.com/a/44134328
  
  
  function CreateCart(props) {
    const { token, shop, location, firebase, host } = props
  
    const existingOffer = location && location.state && location.state.rule
    const rules = location && location.state && location.state.rules
    const isEditing = !!existingOffer
    const isCreating = !isEditing
  
    const initialEditingState = {
      id: existingOffer && existingOffer.id || null, // always null if not created
      offerType: existingOffer && existingOffer.offerType || 'Cart',
      cartType: existingOffer?.cartType || 'page',
      name: existingOffer && existingOffer.name || '',
      banner: existingOffer?.banner ? existingOffer.banner : '',
      layout: existingOffer?.layout || 'row',
      introText: existingOffer && existingOffer.introText || '',
      introTextColor: existingOffer && existingOffer.introTextColor || '#000000',
      topBarBackgroundColor: existingOffer && existingOffer.topBarBackgroundColor || '#E7E7E7',
      popupBorderRadius: existingOffer && existingOffer.popupBorderRadius !== undefined ? existingOffer.popupBorderRadius : 8,
      title: existingOffer && existingOffer.title || '',
      titleColor: existingOffer && existingOffer.titleColor || '#000000',
      description: existingOffer && existingOffer.description || '',
      descriptionColor: existingOffer && existingOffer.descriptionColor || '#000000',
      cta: existingOffer && existingOffer.cta || '',
      compareAtPrice: existingOffer && existingOffer.compareAtPrice || {sym:'$',value:""},
      bodyBackgroundColor: existingOffer && existingOffer.bodyBackgroundColor || '#ffffff',
      ctaBackgroundColor: existingOffer && existingOffer.ctaBackgroundColor || '#ffffff',
      ctaButtonTextColor: existingOffer && existingOffer.ctaButtonTextColor || '#000000',
      ctaButtonBorderColor: existingOffer ? existingOffer?.ctaButtonBorderColor : '#000000',
      ctaTextColor: existingOffer?.ctaTextColor ? existingOffer.ctaTextColor : '#212b36',
      offerBackgroundColor: existingOffer?.offerBackgroundColor ? existingOffer.offerBackgroundColor : '#ffffff',
      offerImageBorderColor: existingOffer?.offerImageBorderColor ? existingOffer.offerImageBorderColor : '#d9d9d9',
      offerImageBorderRadius: existingOffer?.offerImageBorderRadius ? existingOffer.offerImageBorderRadius : 4,
      offerImageBorder: existingOffer?.offerImageBorder ? existingOffer.offerImageBorder : 1,
      productNumber: existingOffer?.productNumber || 1,
      productsToShow: existingOffer?.productsToShow || 2,
      productsPerRow: existingOffer?.productsPerRow || 2,
      buttonBorderRadius: existingOffer && existingOffer.buttonBorderRadius !== undefined ? existingOffer.buttonBorderRadius : 1,
      buttonBorderWidth: existingOffer && existingOffer.buttonBorderWidth !== undefined ? existingOffer.buttonBorderWidth : 1,
      checkoutButtonText: existingOffer && existingOffer.checkoutButtonText || '',
      checkoutButtonBackgroundColor: existingOffer && existingOffer.checkoutButtonBackgroundColor || '#000000',
      checkoutButtonTextColor: existingOffer && existingOffer.checkoutButtonTextColor || '#ffffff',
      checkoutButtonBorderRadius: existingOffer && existingOffer.checkoutButtonBorderRadius !== undefined ? existingOffer.checkoutButtonBorderRadius : 3,
      checkoutButtonHeight: existingOffer && existingOffer.checkoutButtonHeight !== undefined ? existingOffer.checkoutButtonHeight : 35,
      product: existingOffer && existingOffer.product || null,
      variants: existingOffer && existingOffer.variants || null,
      featuredImage: existingOffer
        ? existingOffer?.featuredImage
          ? existingOffer?.featuredImage
          : (existingOffer?.product?.images && existingOffer?.product?.images[0]?.originalSrc)
            ? existingOffer?.product?.images[0]?.originalSrc
            : null
        : null,
      enabled: existingOffer && existingOffer.enabled || false,
      triggerEvent: existingOffer && existingOffer.triggerEvent || 'cart',
      triggers: existingOffer && existingOffer.triggers || {
        conditions:{
            all:[
                {
                    any: [
                        {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                    ]
                }
                ]
        },
        event: { 
            type: 'foundout',
            params: {
              message: 'rule has found out!'
            }
        }
    },
      shopBrain: existingOffer && existingOffer.shopBrain || null,
      aiConditions: existingOffer && existingOffer.aiConditions || {
        customerTags: [],
        products: []
      },
      swap: existingOffer?.swap || false,
      autoAdd: existingOffer?.autoAdd || false,
      isExcludeProduct: existingOffer?.isExcludeProduct || false,
    }
    const [state, setState] = useState(initialEditingState)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [productPickerShow, setProductPickerShow] = useState('product')
    const [error, setError] = useState(null)
    const [productEdit, setProductEdit] = useState(-1)
    const [checkoutOfferType, setCheckoutOfferType] = useState(state.shopBrain === null ? 'manual':'ai');
    const shopDoc = doc(firebase.firestore, 'shops', shop);
    const [shopData, shopDataLoading, shopDataError] = useDocumentData(
      shopDoc
    );

    const handleCheckoutOfferTypeChange = (newValue) => {
      setCheckoutOfferType(newValue)

      if("manual" !== newValue){
          if(state.shopBrain === null){
              if(existingOffer && existingOffer.shopBrain){
                  setState(update(state, { shopBrain: { $set: existingOffer.shopBrain }}))
              }else{
                  setState(update(state, { shopBrain: { $set: { inclusion: [{setting: 'tags', value: null}], exclusion:[{setting: 'tags',value: null}]} }}))
              }
          }
      }else{
          setState(update(state, { shopBrain: { $set: null }}))
      }
    }

    let currentState = Object.assign({},state);
    let initialStateForCheck = Object.assign({},initialEditingState);
    delete currentState.enabled;
    delete initialStateForCheck.enabled;
    const hasUnsavedChanges = isEditing && !equal(currentState, initialStateForCheck)

    const handleRuleDelete = async () => {
      const newRules = await deleteRule(state.id, token, shop, firebase);
      try{
        if(newRules.find(rule => "Cart" === rule.offerType) === undefined){
          await removeCartScript({}, token, shop, host);
        }
      } 
      catch (e) {
          console.log('Error creating rule: ', e)
      }
      
      navigate('/app/offers', {
        state: {
          ...location.state,
          tabIndex: Constants.TAB_INDEX.OFFER_LIST
        },
        replace: true,
      })
    }
    const handleFormSubmit = async (type) => {
  
      if (isSubmitting) {
        return
      }
      if(state.product === null && state.shopBrain === null ){
        setError({product: "Please select a product"})
        return
      }else{
        if(error != null){
          setError(null)
        }
      }
      setIsSubmitting(true)
  
      try {
        if (isEditing) {
          // TODO
          console.log('update rule.')
          await updateRule({
            ...state /* all rule data */,
            triggers: state.triggers ? (state.triggers.conditions.all.length > 0 ? state.triggers: null): null,
            type: state.discount > 0 ? 'discount' : 'regular', // TODO handle product_discount eventually
          }, token, shop, firebase);
  
          navigate(
            `/app/offers/createCart`,
            {
              state: {
                rule: {...state, updatedAt: new Date().toISOString()}
              },
              replace: true,
            }
          )
        } else {
                 
          const result = await createRule({
            ...state /* all rule data */,
            triggers: state.triggers ? (state.triggers.conditions.all.length > 0 ? state.triggers: null): null,
            enabled: "save" === type ? false : true,
          }, token, shop, host)
          navigate(
            `/app/offers/cartOfferPreview`,
            {
              state: {
                rule: result.data.rules.find(rule => rule.id === result.data.id),
              },
              replace: true,
            }
          )
          console.log('result from creating rule: ', result)
        }
  
      }
      catch (e) {
        console.log('Error creating rule: ', e)
      }
  
      setIsSubmitting(false)
    }
  
    const addSmartRule =  () => {
      const conditions = {
        conditions:{
            all:[
                {
                    any: [
                        {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                    ]
                }
                ]
        },
        event: { 
            type: 'foundout',
            params: {
              message: 'rule has found out!'
            }
        }
    }
      setState(update(state, { triggers: { $set: conditions }}))
    }
  
    const currency = (shopData && shopData.shopData && shopData.shopData.currency) ? shopData.shopData.currency : 'USD';
    const currencySymbol = getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : '$';
    const locale = shopData?.shopData?.primary_locale || "en-US";

    return (
      <Page>
        {isEditing ?
          <Fragment>
            <div style={{padding: '10px 0', width: '550px', paddingRight: '3rem'}}>
              <div className="Polaris-Page-Header__Row">
              <div className="Polaris-Page-Header__BreadcrumbWrapper" onClick={() => {
                            navigate('/app/offers', 
                            {
                              state: location.state,
                              replace: true,
                            })
                          }}>
                <nav role="navigation"><a data-polaris-unstyled="true" className="Polaris-Breadcrumbs__Breadcrumb"><span className="Polaris-Breadcrumbs__Icon"><span className="Polaris-Icon"><span className="Polaris-VisuallyHidden"></span><svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M17 9H5.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L5.414 11H17a1 1 0 1 0 0-2z"></path></svg></span></span><span className="Polaris-VisuallyHidden"></span></a></nav>
              </div>
                <div className="Polaris-Page-Header__TitleWrapper">
                  <div className='OfferHeaderContainer'>
                    <div className="Polaris-Header-Title__TitleAndSubtitleWrapper" style={{width: '100%'}}>
                      <div className='OfferHeaderContainer'>
                        <div>
                          <h1 className="Polaris-Header-Title">
                            {initialEditingState.name}
                          </h1>
                          <span>{formatLastSavedDate(existingOffer?.updatedAt)}</span>
                        </div>
                        <div style={{ 
                          display: 'flex',
                          marginLeft: 10,
                          alignItems: 'center'
                        }}>
                          {
                            <div
                              className='OfferStatusMark'
                              style={{backgroundColor: state.enabled ? "#008060" : "#717171"}}
                            />
                          }
                          <OfferStatusButton
                            token={token}
                            shop={shop}
                            firebase={firebase}
                            state={state}
                            setState={setState}
                          />
                        </div>
                      </div>
                      {
                        hasUnsavedChanges &&
                        <Fragment>
                          <Badge>Unsaved Changes</Badge>
                          { false && <div style={{
                            float: 'right',
                            marginRight: '8em',
                          }}>
                            <Button
                              onClick={handleFormSubmit}
                              primary
                              loading={isSubmitting}>
                              Save
                            </Button>
                          </div>
                          }
                        </Fragment>
                      }
                    </div>
                  </div>
              </div>
            </div>
            </div>
          </Fragment> : 
          <div style={{padding: '10px 0'}}>
            <h1 className="Polaris-Header-Title" style={{ display: 'inline'}}>Create Offer</h1>
          </div>
        }
        <Layout>
          <Layout.Section>
          {!existingOffer && (!rules || (rules && Array.isArray(rules) && rules.filter( rule => "Cart" === rule.offerType ).length === 0)) ?
          <div style={{margin: '20px 0 10px -25px'}}>
            <CreateOfferSteps step={2}/>
          </div>
          :
          false
          } 
          <div style={{ paddingRight: '3rem', marginBottom: 50, }}>
            <Form 
                  disabled={
                    !state.product || 
                    !state.name ||
                    !state.title
                  }
                  onSubmit={handleFormSubmit}>
              <CartOfferType host={host} token={token} checkoutOfferType={checkoutOfferType} handleCheckoutOfferTypeChange={handleCheckoutOfferTypeChange} shop={shop} addSmartRule={addSmartRule} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} error={error} productEdit={productEdit} setProductEdit={setProductEdit} currency={currency} locale={locale} currencySymbol={currencySymbol}/>

              <div
                      style={{
                        marginTop: 50,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row-reverse',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div>
                          {
                            isCreating &&
                              <ButtonGroup>
                                <Button
                                    onClick={ () => handleFormSubmit("save")}
                                    primary
                                    loading={isSubmitting}>
                                    Save
                                  </Button>
                                <Button submit primary loading={isSubmitting}>Save and publish</Button>
                              </ButtonGroup>
                          }
                          {
                            isEditing &&
                              <ButtonGroup>
                                <Button 
                                  destructive
                                  outline
                                  onClick={ () => { handleRuleDelete() } }>
                                  Delete
                                </Button>
                                <Button 
                                  submit 
                                  primary 
                                  loading={isSubmitting}>
                                  Save
                                </Button>
                              </ButtonGroup>
                          }
                      </div>
                      <div style={{ marginRight: 30 }}>
                        { isCreating ?
                          <Button 
                            plain
                            destructive={hasUnsavedChanges || isCreating}
                            onClick={() => {
                              navigate('/app/offers', {
                                state: {
                                  ...location.state 
                                },
                                replace: true,
                              })
                            }}
                          >
                              {
                              hasUnsavedChanges ? 
                              'Discard Changes' :  'Discard'
                              }
                          </Button>
                          :
                          <Button 
                            plain
                            destructive={hasUnsavedChanges || isCreating}
                            onClick={() => {
                              navigate('/app/offers', 
                              {
                                state: location.state,
                                replace: true,
                              })
                            }}
                            >
                            {
                              hasUnsavedChanges ? 
                              'Discard Changes' : 'Back'
                            }
                          </Button>
                          }
                      </div>
                    </div>
              </Form>
            </div>
          </Layout.Section>
          <Layout.Section secondary>
                  <div
                    style={{
                      position: 'fixed',
                      marginRight: 30,
                      width: 500, // this is the max width of the actual shopify checkout page
                    }}
                  >
                    <CartPreview 
                      {...state} 
                      {...props}
                      checkoutOfferType={checkoutOfferType}
                      productEdit={productEdit}
                      image={state.product && state.product.image}
                      price={state.variants && state.variants[0].price}
                      shopData={shopData}
                    />
                  </div>
          </Layout.Section>
        </Layout>
      </Page>
    );
  }
  
  export default withFirebase(CreateCart);